.GameRoom {
    height: 100%;
    position: relative;

    display: flex;
    /* flex-flow: column nowrap; */
    justify-content: center;
    align-items: center;
}

.GameStart {
	position: absolute;
	z-index: 4;

    opacity: 0.95;

    position: absolute;
	top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    transition: opacity 300ms linear;
}

.GameStart.closed {
    opacity: 0;
    pointer-events: none;
}

.GameSheet {
    background-color: rgb(246, 248, 241);
    border: 3px dashed darkgray;
    border-radius: 10px;

    min-height: 60%;
    max-height: 80%;
    min-width: 70%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;

    text-align: center;
}

.ConnectionWatcher {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms linear 500ms;
}

.ConnectionWatcher .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: black;
    opacity: 0.3;
}

.ConnectionWatcher svg {
    color: gold;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 25px;
    margin-right: 100px;
    animation: pulse 1s infinite;
}

.ConnectionWatcher.connected svg {
    animation-play-state: paused;
}

.ConnectionWatcher.disconnected {
    opacity: 1;
}

.Menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
  
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  
  }
  
  .menubutton {
    width: 37px;
    height: 37px;
    pointer-events: all;
    background-color: rgba(255,255,255,0.3);
    border-radius: 3px;
    box-shadow: 2px 2px rgba(0,0,0,0.2);
    border: none;
    outline: none;
  }
  
  .Menu>.menubutton {
    position: absolute;
    margin-right: 20px;
    margin-top: 25px;
    right: 0;
    top: 0;
  }
  
  .menubutton svg {
    font-size: 30px;
    position: absolute;
    left: 6px;
    top: 3.5px;
    color: black;
    transition: opacity 200ms linear;
  }
  
  svg.open-icon {
    opacity: 1;
  }
  
  svg.close-icon {
    left: 8.5px;
    opacity: 0;
  }
  
  .menu-open svg.open-icon {
    opacity: 0;
  }
  
  .menu-open svg.close-icon {
    opacity: 1;
  }
  
  .menubutton:focus {
    outline: none;
  }
  
  .menubutton:hover {
    background-color: rgba(255,255,255,0.5);
  }
  
  .MenuContent {
    min-height: 50%;
    min-width: 50%;
    max-width: 90%;
    max-height: 100%;
    opacity: 0;
    pointer-events: none;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid gray;
    background-color: rgba(240,240,240,0.99);
    box-shadow:#222 10px 10px 20px;
    transition: opacity 300ms ease-in-out;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .menuTopRow {
    align-self: stretch;
    flex-grow: 0;
    flex-shrink: 0;

    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: space-between;
  }

  .menuTopRow button {
    height: 37px;
    width: 37px;
    font-size: 30px;
  }

  .menuTopRow button svg {
    position: relative;
    top: -11px;
    left: -4px;
  }

.menu-action-button {
    margin: 10px;
    flex-grow: 0;
    flex-shrink: 1;
    width: 80%;
  }
  
  .menu-open .MenuContent {
    opacity: 1;
    pointer-events: initial;
  }
  
  
.ScoreBoard {
    max-height: 100%;
    max-width: 100%;
    overflow: scroll;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    flex-grow: 0;
    flex-shrink: 10;
    background-color: rgb(231, 231, 230);
}

.ScoreBoardTitle {
  font-size: 1.5em;
}

.ScoreBoardTable {
  
    overflow: scroll;
}

.ScoreBoardTable tr:first-child th {
    width: 15%;
}

.ScoreBoardTable tr:first-child th:first-child {
    width: 30%;
}

.ScoreBoardTable td,th {
    text-align: center;
}

.ScoreBoardTable td:first-child, .ScoreBoardTable th:first-child {
    text-align: left;
}