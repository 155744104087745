
.playingcard {
	position: absolute;
	transform: rotate(0) scale(1);
	width: 69px;
	height: 94px;
	cursor: pointer;
	border-radius: 8px;
	transition: left 0.6s ease-in-out, top 0.6s ease-in-out, opacity 0.2s linear;
}

.playingcard.fast-move {
	transition: left 0.2s ease-out, top 0.2s ease-out, opacity 0.2s linear;
}

.playingcard img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: opacity 0.5s ease-in 0.5s;
	pointer-events: none;
}

.playingcard .faceup-img {
	opacity: 0;
}

.playingcard .facedown-img {
	opacity: 1;
}

.playingcard.faceup .faceup-img {
	opacity: 1;
}

.playingcard.faceup .facedown-img {
	opacity: 0;
}

.playingcard.ui-draggable-dragging {
	z-index: 2000;
	transform: scale(1.2);
	opacity: 0.9;
	transition: none;
}

.playingcard.transparent {
	opacity: 0.9;
}

.playingcard.ui-draggable-dragging img {
	transition: opacity 0.2s ease-in;
}

.CardTable {
	padding: 5px;
	background-color:green;
	overflow: hidden;
	border:solid 6px brown;
	border-radius:8px;
	box-shadow:#111 1px 1px 2px;
	user-select: none;
	transition: border-color 1s;

	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	display: flex;
	flex-flow: column nowrap;

	width: 100%;
	min-height: 100%;
}

.CardTable.in-turn {
	border-color: orange;
}

.other-player {
	max-height: 120px;
	margin: 2px;
	padding: 3px 0px;

	box-shadow: -5px -5px 10px darkgreen;
	border: 1px solid darkgreen;
	border-radius: 10px;

	display: flex;
	flex-flow: row nowrap;
}

.other-player.disconnected {
	background-color: rgb(40, 63, 40) !important;
}

.other-player .player-name {
	color: white;
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	font-size: 1.2em;
    overflow: hidden;
	width: 1.4em;
	padding: 10px 0;
	text-align: center;
	flex-shrink: 0;
}

.other-player.in-turn .player-name {
	font-weight: bold;
}

.player-connection-indicator {
	margin-left: auto;
	align-self: flex-end;
  position: relative;
	color: red;
	font-size: 2em;
	text-align: right;
  left: -40px;
  width: 0;
}

.player-hands {
	display: flex;
	flex-flow: row wrap;

}

.player-hand {
	float:left;
	border-radius: 10px;
	margin: 0px 5px;
	height: 105px;
	align-self: center;
}

.closed-hand {
	/* min-width: 100px; */
	z-index: 1000;
}

.open-hand {
	border: 1px solid transparent;
}

.turn-indicator {
	background-color: inherit;
    transition: background-color 0.7s linear;
}

.turn-indicator.in-turn {
	background-color: rgb(46, 177, 57);
	transition: background-color 0.7s linear 0.7s;
}

#gamearea.turn-indicator.in-turn {
	animation: color-me-in 1.5s;
}

#gamearea {
	width: 100%;
	border-radius: 10px;
	display: flex;
	flex-flow: column nowrap;
}

#controlrow {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
}

#infocolumn {
	color: white;
	text-align: center;
	display: flex;
	flex-flow: column nowrap;
	margin: 10px 30px;
}

#roundinfo {
	font-size: 1.2em;
}

#instructions {
}

.winner {
	font-size: 1.3em;
	color: yellow;
	font-style: bold;
}

#controls {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

#controls button {
	margin: 0px 20px;
	min-width: 100px;
	flex-grow: 0;
	flex-shrink: 0;
}

.btn-light.disabled, .btn-light:disabled {
	opacity: 0.4;
}

#deckrow {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
}

#deck,#pile {
	margin: 10px;
	height: 110px;
	width: 100px;
	border-radius: 10px;
	border: 1px dashed darkgreen;
	transition: background-color 300ms linear;

	flex-shrink: 0;
	flex-grow: 0;
}

#deck.canDrag,#pile.canDrag {
	border-color: transparent;
	background-color: rgba(255,255,255,0.4);
	transition-delay: 3000ms;
	animation: pulse 1.5s infinite 3000ms;
}

.CardTable.small-game #deck.canDrag,.CardTable.small-game #pile.canDrag {
	animation-delay: 0ms;
	transition-delay: 0ms;
}

#pile.canBuy {
	border-style: solid;
	border-color: darkorange;
	background-color: rgb(248, 175, 86);
}

#my-closed-hand-sections {
	overflow: visible;
	border-radius: 10px;
	margin-top: 20px;
	margin-bottom: 10px;
	margin-left: 0px;
	border-top: 1px dotted darkgreen;

	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
}

#bought-indicators {
	position: relative;
	align-self: start;
	top: 0%;
	left: 50%;
	height: 10px;
	width: 0px;
	overflow: visible;
	transform: translateX(-30px) translateY(-50%);

	display: flex;
}

.bought-indicator {
	display: inline-block;
	flex-shrink: 0;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	margin: 0px 5px;
	border: 1px solid darkgreen;
}

.bought-indicator.used {
	background-color: darkgreen;
}

.section, .new-section {
	height: 105px;
	margin: 5px 0px;
	border: 2px solid transparent;
	border-radius: 10px;
	pointer-events: none;
	overflow: visible;
}

.section {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
}

.section .placeholder {
	position: relative;
	top: 3px;
	border: 1px dashed darkgreen;	
}

.section .status-box{
	position: relative;
	float: right;
	color: white;
	bottom: 52px;
	display: none;
}

.section.selected .status-box {
	display: block;
}

.CardTable .ui-droppable-hover {
	border-width: 2px !important;
	border-style: solid !important; 
	border-color: gold !important;
}

.new-section {
	height: 94px;
	width: 69px;
	color: darkgreen;
	font-size: 3em;
	vertical-align: middle;
	border-width: 1px;
	position: relative;
	border: 1px dashed darkgreen;
}

.new-section div {
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.CardTable.selecting .hand-section {
	pointer-events: all;
}

.CardTable.selecting .hand-section:not(.selected):not(.error) {
	border-color: gray;
}

.CardTable.selecting .hand-section:hover:not(.error) {
	border-color: orange;
}

.CardTable.selecting .hand-section.selected {
	background-color: orange;
	border-color: rgb(210,136,0);
}

.CardTable.selecting .playingcard {
	pointer-events: none;
	transform: scale(0.9);
}

.Hand {
	position: relative;
}

.round-ended .closed-hand.Hand, .round-ended .hand-section {
	position: relative;
	z-index: 1000;
}

.hand-score {
	display: none;
	border-radius: 5px;
	height: 25px;
	width: 35px;
	top: 50%;
	left: 50%;
	text-align: center;
	font-size: 1.1em;
	font-weight: bold;
	position: absolute;
	transform: translateX(-50%) translateY(-50%);

	color: white;
	background-color: darkred;
}

.round-ended .closed-hand .hand-score, .round-ended #section0 .hand-score {
	display: block;
}

.hand-length {
	width: 30px;
	padding: 1px;
	min-width: 15px;
	position: absolute;
	top: 50%;
	left: 50%; 
	text-align: center;
	font-size: 1em;
	font-weight: bold;
	border-radius: 4px;
	transform: translateX(-50%) translateY(-50%);
	color: black;
	background-color: rgba(255,255,255,0.9);
}

.popover {
	z-index: 1000000;
	pointer-events: none;
}

@keyframes color-me-in {
	0% {
		background-color: inherit;
	  }
	20% {
		background-color: inherit;
	  }
	50% {
	  background-color: rgb(167, 221, 171);
	}
	70% {
		background-color: rgb(167, 221, 171);
	}
	100% {
	  background-color:  rgb(46, 177, 57);;
	}
  }

@media only screen and (max-width: 767px) {

	.playingcard {
		overflow: hidden;
		border-radius: 7px;
		border: 1px solid #303030a0;
		width: 40px;
		height: 60px;
	}
	
	.playingcard img {
		top: 40px;
		left: 27px;
		height: 100%;
		transform: scale(2.5);
	}
	
	.playingcard img.facedown-img {
		top: 27px;
		left: 18px;
		transform: scale(2);	
	}
	
	.playingcard.ui-draggable-dragging {
		transform: scale(1.4) translateX(+10%) translateY(-30%);
		opacity: 0.8;
		transition: none;
	}

	.CardTable .ui-droppable-hover {
		background-color: gold;
	}

	.other-player {
		max-height: unset;
		flex-flow: row nowrap;
	}
	
	.other-player .player-name {
		padding: 10px 0;
		text-align: center;
		max-height: 150px;
	}
	
	.player-hands {
		justify-content: flex-end;
		flex-grow: 1;
	}
	
	.closed-hand {
		min-width: unset;
	}
	
	.spacer {
		min-width: 0;
		flex-grow: 1;
		flex-shrink: 1;
	}
	
	.player-hand {
		/* float:left;
		border-radius: 10px; */
		margin: 0px 0px;
		height: 66px;
		/* align-self: center; */
	}
	
	#controlrow {
		flex-flow: row wrap;
		/* justify-content: center; */
	}
	
	#deckrow {
		justify-content: flex-end;
	}
	
	#deck, #pile {
		/* margin: 10px; */
		height: 70px;
		width: 60px;
	}
	
	#deck.canDrag, #pile.canDrag {
		animation: none;
	}
	
	#my-closed-hand-sections {
		margin-top: 0px;
		margin-bottom: 0px;
	}
	
	.section, .new-section {
		height: 70px;
		min-width: 10px;
		margin: 5px 0px;
	}
	
	.new-section {
		height: 60px;
		width: 40px;
	}
	
	.hand-score {
	  background-color: rgba(139, 0, 0, 0.8);
		top: 0; 
		left: 50%;
	  transform: translateX(-50%) translateY(-50%);
	}
}

