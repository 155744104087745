html, body, #root {
  height: 100%;
}

button:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.App {
  height: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Lobby {
  min-height: 100%;
  width: 100%;
  padding: 20px;
  background-color: green;
  color: white;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

#inputGameCode, #inputNick {
  width: 4em;
  margin: auto;
  font-size: 1.5em;
  text-align: center;
}

#inputNick {
  width: 8em;
}

.text-muted.text-gray {
  color: lightgray !important;
  margin-bottom: 20px;
}

#rejoinGameTable {
  background-color: rgba(255,255,255,0.5);
}

#rejoinGameTable tr:not(:first-child) {
  cursor: pointer;
}

#rejoinGameTable.table-hover tbody tr:first-child:hover {
  color: initial;
  background-color: initial;
}

#rejoinGameTable .exit-button {
  margin-left: 30px;
}